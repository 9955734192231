
$(function(){

  // eふれんずへのリンクをUAにより分岐
  var isMobile = false;

  if ('userAgentData' in window.navigator) {
    // userAgentData が有効なので、userAgentDataで判定をする
    const useAgentDataMobile = navigator.userAgentData.mobile;
    if( useAgentDataMobile ){
      isMobile = true;
    }
  }
  else {
    // userAgentData が無効なので、既存のユーザーエージェント判定をする
    if ( (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) || (navigator.userAgent.match(/Blackberry/i)) || (navigator.userAgent.match(/Windows Phone/i)) ) {
      isMobile = true;
    }
  }

  if ( isMobile ) {
    // efriens リンク切り替え用処理
      $(".sys_efriends_link").each(function() {
          $(this).attr("href", "https://csnet.ef.cws.coop/ec/sp/ecTopInit.do")
      });

      // $(".sys_nengajo_kohai_link").each(function() {
      //     $(this).attr("href", "https://csnet.ef.cws.coop/auth/sp/login.do")
      // });

      // 電話番号
      replaceTelNumber();
  }



});



//電話番号
function replaceTelNumber () {

    $('[data-js="tel"]').each(function() {
      var str = $(this).html();
      if ($(this).children().is('img')) { //子要素がimgの場合、alt属性を取得して電話番号リンクとgaイベントを追加
        $(this).html($('<a>').attr({
          href: 'tel:' + $(this).children().attr('alt').replace(/-/g, ''),
          onclick: "gtag('event', 'アクション', {'event_category': 'カテゴリ','event_label': 'ラベル'});"
        }).append(str + '</a>'));
      } else { //テキストを取得して電話番号リンクとgaイベントを追加
        $(this).html($('<a>').attr({
          href: 'tel:' + $(this).text().replace(/-/g, ''),
          onclick: "gtag('event', 'tap', {'event_category': '電話番号','event_label': '電話番号'});"
        }).append(str + '</a>'));
      }
    });
}